import React, { useState, useEffect } from "react";
import headerStyles from "../styles/components/header.module.scss";
import layoutStyles from "../styles/components/layout.module.scss";
import useSiteLogo from "../static_queries/useSiteLogo";
import { Link } from "gatsby"

export default function Header(props) {
  const siteLogo = useSiteLogo();

  const [menuOpen, setMenuOpen] = useState(false);
  const [scroll, setScroll] = useState(["0px", "100px"]);
  const [activeSection, setActiveSection] = useState('#home');
  const [isMobile, setIsMobile] = useState(false);

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (menuOpen && isMobile) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen, isMobile]);

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      var prevScrollpos = window.pageYOffset
      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset
        if (currentScrollPos <= 0) {
          setScroll(["0px", "100px"])
        } else if (prevScrollpos < currentScrollPos) {
          setScroll(["-100px", "100px"])
        } else if (prevScrollpos > currentScrollPos) {
          setScroll(["0px", "80px"])
        }
        prevScrollpos = currentScrollPos
      }
    }
    return () => {
      isMounted = false
      window.removeEventListener("scroll", function () {
        var currentScrollPos = window.pageYOffset
        if (prevScrollpos < currentScrollPos) {
          setScroll(["-100px", "100px"])
        } else {
          if (currentScrollPos <= 0) {
            setScroll(["100px", "100px"])
          } else {
            setScroll(["0px", "80px"])
          }
        }
        prevScrollpos = currentScrollPos
      })
    }
  }, [scroll])

  useEffect(() => {
    const sectionIds = ['#home', '#work', '#lab', '#about', '#contact'];
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      let activeId = '';

      sectionIds.forEach((id) => {
        const el = document.querySelector(id);
        if (el) {
          const rect = el.getBoundingClientRect();
          const elemTop = rect.top + window.scrollY;
          const elemBottom = elemTop + el.offsetHeight;
          if (currentScrollPosition >= elemTop && currentScrollPosition < elemBottom) {
            activeId = id;
          }
        }
      });

      setActiveSection(activeId);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = (e, sectionId) => {
    if (e) {
      // e.preventDefault();
      setMenuOpen(false);
      setActiveSection(sectionId);

      const section = document.querySelector(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth',
        });
      }
    }
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className={`${headerStyles.header} ${props.page === "info" && headerStyles.info_page}`}
      style={{
        transform: `translateY(${scroll[0]})`,
        transition: "transform 300ms linear",
        height: scroll[1],
      }}
    >
      <nav
        className={headerStyles.header__nav + " " + layoutStyles.e_layout}
        role="navigation"
        aria-label="main navigation"
      >
        {siteLogo
          .filter(logo => logo.node.frontmatter.site_logo != null)
          .map((item, idx) => (
            <img
              alt="Ashley Van Laer Logo"
              key={idx}
              src={item.node.frontmatter.site_logo.childImageSharp.fluid.src}
              className={headerStyles.logo}
            />
          ))}
        <div className={headerStyles.menu + " " + (menuOpen ? headerStyles.open__menu : "")}>
          <Link to="/#home" onClick={(e) => toggleMenu(e, '#home')} className={activeSection === '#home' ? headerStyles.active : ''}>
            Home
            <span />
          </Link>
          <Link to="/#work" onClick={(e) => toggleMenu(e, '#work')} className={activeSection === '#work' ? headerStyles.active : ''}>
            Work
            <span />
          </Link>
          <Link to="/#lab" onClick={(e) => toggleMenu(e, '#lab')} className={activeSection === '#lab' ? headerStyles.active : ''}>
            Lab
            <span />
          </Link>
          <Link to="/#about" onClick={(e) => toggleMenu(e, '#about')} className={activeSection === '#about' ? headerStyles.active : ''}>
            About
            <span />
          </Link>
          <Link to="/#contact" onClick={(e) => toggleMenu(e, '#contact')} className={activeSection === '#contact' ? headerStyles.active : ''}>
            Contact
            <span />
          </Link>
        </div>
        <div
          className={
            !menuOpen
              ? headerStyles.hamburger + " " + headerStyles.isActive
              : headerStyles.hamburger
          }
        >
          <div className={headerStyles.menu__1}>
            <label>
              <input
                type="checkbox"
                onClick={() => toggleMenu()}
                checked={!menuOpen ? false : true}
                className={headerStyles.input}
              />
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="30" />
                <path
                  className={headerStyles.line__1}
                  d="M0 40h62c13 0 6 28-4 18L35 35"
                />
                <path className={headerStyles.line__2} d="M0 50h70" />
                <path
                  className={headerStyles.line__3}
                  d="M0 60h62c13 0 6-28-4-18L35 65"
                />
              </svg>
            </label>
          </div>
        </div>
      </nav>
    </header>
  );
}
