import firebase from "gatsby-plugin-firebase"
import "firebase/firestore";

// const prodConfig = {
//   apiKey: "AIzaSyBGklmPWx_ETjnwPuSya0qxbNUVKhb3DDo",
//   authDomain: "portfolio-ashleyvanlaer.firebaseapp.com",
//   databaseURL: "https://portfolio-ashleyvanlaer.firebaseio.com",
//   projectId: "portfolio-ashleyvanlaer",
//   storageBucket: "portfolio-ashleyvanlaer.appspot.com",
//   messagingSenderId: "922724909251",
//   appId: "1:922724909251:web:48458ebc9303cc2f9ae276",
//   measurementId: "G-3SWZE1G6XS"
// };

// const devConfig = {
//   apiKey: "AIzaSyBGklmPWx_ETjnwPuSya0qxbNUVKhb3DDo",
//   authDomain: "portfolio-ashleyvanlaer.firebaseapp.com",
//   databaseURL: "https://portfolio-ashleyvanlaer.firebaseio.com",
//   projectId: "portfolio-ashleyvanlaer",
//   storageBucket: "portfolio-ashleyvanlaer.appspot.com",
//   messagingSenderId: "922724909251",
//   appId: "1:922724909251:web:48458ebc9303cc2f9ae276",
//   measurementId: "G-3SWZE1G6XS"
// };

// const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

// if (!firebase.apps.length) {
//   firebase.initializeApp(config);
// }

const db = firebase.firestore()

export { db };
