import { db } from './firebase';

  export function sendMail(data, callback) {
    db.collection("mail")
    .doc()
    .set({
      to: "ashley_vanlaer@hotmail.com",
      message: {
        subject: "Nieuw bericht van:" + data.name,
        text: "from: " + data.email + "\nName: " + data.name + "\n" + "Interested in: " + data.interest + "\n" + "\n" + data.message,
      },
    })
    .then((e) => {
      callback('success')
    })
    .catch(function(error) {
      callback(error)
    });
  }