import React, { useState } from "react"
import contactStyles from "../styles/components/contact.module.scss"
import FeatherIcon from "feather-icons-react"
import { Link } from "gatsby"
import AutosizeInput from "react-input-autosize"
import TextareaAutosize from "react-textarea-autosize"
import layoutStyles from "../styles/components/layout.module.scss"
import { db } from "../firebase"

export default function Contact() {
  const date = new Date()
  const year = date.getFullYear()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [interest, setInterest] = useState("")
  const [message, setMessage] = useState("")
  const [formSendSucces, setFormSendSucces] = useState(false)
  const [wrongField, setWrongField] = useState({
    type: "",
    message: "",
  })

  const onSubmit = () => {
    var emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    if (name === "" || name === " ") {
      setWrongField({
        type: "name",
        message: "Fill out your name.",
      })
    } else if (emailRegex.test(email) === false) {
      setWrongField({
        type: "email",
        message: "Fill out a valid email.",
      })
    } else if (interest === "" || interest === " ") {
      setWrongField({
        type: "interest",
        message: "What are you interested in?",
      })
    } else if (message === "" || message === " ") {
      setWrongField({
        type: "message",
        message: "Type your message.",
      })
    } else {
      setWrongField({
        type: "",
        message: "",
      })
      const data = {
        name: name,
        email: email,
        interest: interest,
        message: message,
      }
      db.sendMail(data, e => {
        if (e === "success") {
          setFormSendSucces(true)
        } else {
        }
      })
    }
  }

  return (
    <section className={contactStyles.contact__main}>
      <div className={layoutStyles.vline + " " + layoutStyles.pageLine}></div>
      <div className={contactStyles.bullet} />
      <div className={contactStyles.page_titles}>
        <div className={contactStyles.first_title}>
          <h5>Contact</h5>
          <h2>
            Do you like what you see? Don’t hesitate to send me a message. I
            will come back at you as soon as possible.
          </h2>
        </div>
      </div>

      {formSendSucces ? (
        <div className={contactStyles.contact_form}>
          <p>Thank you! Your message has been successfully sent.</p>
        </div>
      ) : (
        <div className={contactStyles.contact_form}>
          Hi, my name is{" "}
          <AutosizeInput
            name="form-field-name"
            placeholder="your name"
            value={name}
            onChange={function(event) {
              setName(event.target.value)
            }}
          />{" "}
          and my email is{" "}
          <AutosizeInput
            name="form-field-name"
            placeholder="your email"
            value={email}
            onChange={function(event) {
              setEmail(event.target.value)
            }}
          />
          .<br />
          I'm interested in{" "}
          <AutosizeInput
            name="form-field-name"
            placeholder="webapps"
            value={interest}
            onChange={function(event) {
              setInterest(event.target.value)
            }}
          />
          .<br />
          <TextareaAutosize
            placeholder="your message"
            onChange={function(event) {
              setMessage(event.target.value)
            }}
          />
          {wrongField.type !== "" && (
            <p className={contactStyles.wrongfield}>{wrongField.message}</p>
          )}
          <br />
          <button
            className={layoutStyles.main_btn}
            style={{ marginTop: "20px" }}
            onClick={onSubmit}
          >
            Send
            <FeatherIcon
              icon="arrow-right"
              size="22"
              color="#000000"
              strokeWidth="2"
            />
          </button>
        </div>
      )}

      {/* <div className={contactStyles.sec_title}>
        <h2>Find me on:</h2>
        <ul>
          <li>
            <a href="https://www.linkedin.com/in/ashley-van-laer/">
              <FeatherIcon
                icon="linkedin"
                size="22"
                color="#64F0BF"
                strokeWidth="2"
              />
              in/ashley-van-laer
            </a>
          </li>
          <li>
            <a href="https://github.com/VanLaerAshley">
              <FeatherIcon
                icon="github"
                size="22"
                color="#64F0BF"
                strokeWidth="2"
              />
              github.com/VanLaerAshley
            </a>
          </li>
        </ul>
      </div> */}

      <p className={contactStyles.copyright}>
        ©{year} Digital North BV • BE0798.932.679 • All rights reserved -
        <Link to="/cookie-policy"> Cookie policy </Link>-
        <Link to="/privacy-policy"> Privacy policy </Link>
      </p>
    </section>
  )
}
