import React, { Component } from "react"
import FeatherIcon from "feather-icons-react"
import disableScroll from "disable-scroll"
import "./VideoPlayer.scss"
import AnimatedCursor from "../AnimatedCursor"
import { isMobile } from "react-device-detect"

// import './VideoPlayer.scss'

export default class VideoPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasVideoStarted: false,
      hasVideoEnded: false,

      isPlaying: false,
      isFullScreen: false,

      isMuted: false,
      volume: 100,

      time: 0,
      videoLength: 0,
      maxSteps: 0,
      formatedCurrent: "00:00",
      formatedDuration: "00:00",

      mouseOut: false,
    }
  }

  addEvent = (obj, evt, fn) => {
    if (obj.addEventListener) {
      obj.addEventListener(evt, fn, false)
    } else if (obj.attachEvent) {
      obj.attachEvent("on" + evt, fn)
    }
  }

  componentDidMount() {
    disableScroll.on()
    this.addEvent =
      (document,
      "mouseout",
      function(e) {
        e = e ? e : window.event
        var from = e.relatedTarget || e.toElement
        if (!from || from.nodeName === "HTML") {
          this.setState({
            mouseOut: true,
          })
        } else {
          this.setState({
            mouseOut: false,
          })
        }
      })

    document.addEventListener("keydown", event => {
      if (event.code === "Space") {
        event.preventDefault()
        this.playPauseVideo()
        this.setState({
          hasVideoStarted: true,
        })
      }
      if (event.code === "Escape" && !this.state.isFullScreen) {
        this.props.toggleModal(false)
      }
    })

    const prefixes = ["", "moz", "webkit", "ms"]
    const self = this
    prefixes.forEach(function(prefix) {
      document.addEventListener(prefix + "fullscreenchange", function() {
        console.log(document.fullscreenElement)
        if (
          document.fullscreenElement === null ||
          document.webkitFullscreenElement === null ||
          document.mozFullScreenElement === null ||
          document.msFullscreenElement === null
        ) {
          console.log(self.state.isFullScreen, "here")
          if (self.state.isFullScreen) {
            console.log("exit fullscreen")
            self.setState({ isFullScreen: false })
          }
        }
      })
    })
  }
  componentWillUnmount() {
    disableScroll.off()

    document.removeEventListener("keydown", {})
    const prefixes = ["", "moz", "webkit", "ms"]
    prefixes.forEach(function(prefix) {
      document.removeEventListener(prefix + "fullscreenchange", {})
    })
    document.removeEventListener("keydown", {})
  }

  initialStartVideo = () => {
    this.refs.elemVideo.play()
    this.setState({ isPlaying: true, hasVideoStarted: true })
  }

  replayVideo = () => {
    this.setState({ isPlaying: true, hasVideoEnded: false, time: 0 })
    this.refs.elemVideo.play()
  }

  goFullScreen = () => {
    if (this.state.isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen()
      }

      this.setState({ isFullScreen: false })
    } else {
      disableScroll.off()
      let elem = this.refs.videoContainer

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      }

      this.setState({ isFullScreen: true })
    }
  }

  setTotalTime = () => {
    let sec = this.refs.elemVideo.duration
    let steps = Math.round(this.refs.elemVideo.duration)

    let minutes = Math.floor(steps / 60)
    let seconds = steps - minutes * 60

    let timeString = `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`
    this.setState({
      videoLength: sec,
      maxSteps: steps,
      formatedDuration: timeString,
    })
  }

  updateVideoBar = () => {
    let sec = Math.round(this.refs.elemVideo.currentTime)

    let minutes = Math.floor(sec / 60)
    let seconds = sec - minutes * 60

    let timeString = `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`

    this.setState({ time: sec, formatedCurrent: timeString })

    if (this.state.videoLength == this.refs.elemVideo.currentTime) {
      this.setState({ hasVideoEnded: true })
    }
  }

  changeScene = value => {
    this.refs.elemVideo.currentTime = value
    this.setState({ time: value })
  }

  playPauseVideo = () => {
    if (this.state.isPlaying) {
      this.refs.elemVideo != undefined && this.refs.elemVideo.pause()
      this.setState({ isPlaying: false })
    } else {
      this.refs.elemVideo != undefined && this.refs.elemVideo.play()
      this.setState({ isPlaying: true })
    }
  }

  changeVolume = value => {
    this.refs.elemVideo.volume = value / 100
    this.setState({ volume: value })

    if (value == 0) {
      this.setState({ isMuted: true })
    } else {
      this.setState({ isMuted: false })
    }
  }

  muteVolume = () => {
    if (this.state.isMuted) {
      this.refs.elemVideo.muted = false
      this.setState({ isMuted: false })
    } else {
      this.refs.elemVideo.muted = true
      this.setState({ isMuted: true })
    }
  }

  render() {
    const {
      hasVideoStarted,
      hasVideoEnded,
      isPlaying,
      isFullScreen,
      isMuted,
      volume,
      time,
      videoLength,
      maxSteps,
      formatedCurrent,
      formatedDuration,
    } = this.state
    const { media } = this.props
    return (
      <div
        ref="videoContainer"
        className={`c-videoplayer-container fullscreen ${
          !hasVideoStarted ? "" : "enabled"
        } ${isPlaying ? "" : "not-playing"}`}
      >
        {!hasVideoStarted && (
          <button
            className="c-videoplayer-hoverbutton needs-fill"
            onClick={this.initialStartVideo}
          >
            <FeatherIcon icon="play" size="24" />
          </button>
        )}
        {hasVideoEnded && (
          <button
            className="c-videoplayer-hoverbutton"
            onClick={this.replayVideo}
          >
            <FeatherIcon icon="rotate-cw" size="24" />
          </button>
        )}
        <video
          poster={media != undefined && media.thumbnail}
          ref="elemVideo"
          className={`c-videoplayer-video ${isFullScreen ? "fullscreen" : ""}`}
          src={media != undefined && media.video}
          type="video/mp4"
          onTimeUpdate={this.updateVideoBar}
          onCanPlay={this.setTotalTime}
          onClick={
            hasVideoStarted ? this.playPauseVideo : this.initialStartVideo
          }
        />
        <section className="c-videoplayer-controls">
          <div className="c-videoplayer-timing">
            <span className="text-left">{formatedCurrent}</span>
            <input
              type="range"
              min="0"
              max={maxSteps}
              value={time}
              step="1"
              className="c-videoplayer-timebar"
              style={{
                background:
                  "linear-gradient(to right, #64f0bf 0%, #64f0bf " +
                  (time * 100) / maxSteps +
                  "%, #BBC0C0 " +
                  (time * 100) / maxSteps +
                  "%, #BBC0C0 100%)",
              }}
              onChange={event => this.changeScene(event.target.value)}
            />
            <span className="text-right">{formatedDuration}</span>
          </div>
          <div className="c-videoplayer-actions">
            <div className="c-videoplayer-left">
              <button
                className="c-videoplayer-button"
                onClick={this.playPauseVideo}
              >
                <FeatherIcon icon={isPlaying ? "pause" : "play"} size="18" />
              </button>
              <div className="c-videoplayer-volume">
                <button
                  className="c-videoplayer-button"
                  onClick={this.muteVolume}
                >
                  {isMuted && <FeatherIcon icon="volume-x" size="18" />}
                  {!isMuted && volume > 0 && (
                    <FeatherIcon
                      icon={volume < 50 ? "volume-1" : "volume-2"}
                      size="18"
                    />
                  )}
                </button>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={isMuted ? 0 : volume}
                  className="c-videoplayer-volumebar"
                  style={{
                    background:
                      "linear-gradient(to right, #FFFFFF 0%, #FFFFFF " +
                      (isMuted ? 0 : volume) +
                      "%, #BBC0C0 " +
                      (isMuted ? 0 : volume) +
                      "%, #BBC0C0 100%)",
                  }}
                  onChange={event => this.changeVolume(event.target.value)}
                />
              </div>
            </div>
            <div className="c-videoplayer-right">
              <button
                className="c-videoplayer-button"
                onClick={this.goFullScreen}
              >
                <FeatherIcon
                  icon={isFullScreen ? "minimize-2" : "maximize-2"}
                  size="18"
                />
              </button>
            </div>
          </div>
        </section>
        {!isMobile && isFullScreen && (
          <AnimatedCursor opacity={this.state.mouseOut ? 0 : 1} />
        )}
      </div>
    )
  }
}
