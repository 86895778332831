import React, { useRef, useState, useEffect } from "react"
import blogListStyles from "../styles/components/hero.module.scss"
import TextLoop from "react-text-loop"
import arrows from ".././assets/arrows-down.png"
import leftBooster from ".././assets/left-booster.svg"
import rocket from ".././assets/rocket.svg"
import rightBooster from ".././assets/right-booster.svg"

import * as basicScroll from "basicscroll"
import layoutStyles from "../styles/components/layout.module.scss"


const TEXTS = ["websites", "webapps", "mobile apps", "web shops", "UX/UI"]
export default function Hero(props) {
  const leftBoosterRef = useRef(null)
  const rightBoosterRef = useRef(null)
  const rocketRef = useRef(null)
  const opacityRef = useRef(null)

  const [pageHeight, setPageHeight] = useState(0)
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  )
  if (typeof window !== `undefined`) {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth)
    })
  }
  
  useEffect(() => {
    setPageHeight(document.body.clientHeight)
    return () => {
      setPageHeight(document.body.clientHeight)
    }
  }, [pageHeight])
  basicScroll
    .create({
      elem: leftBoosterRef,
      from: screenWidth <= 414 ? 350 : 0,
      to: screenWidth <= 414 ? 850 : 500,
      direct: false,
      props: {
        "--lr": {
          from: "0",
          to: "-150deg",
        },
        "--lry": {
          from: "0px",
          to: "270px",
        },
        "--lrx": {
          from: "0px",
          to: "-970px",
        },
        "--lo": {
          from: "1",
          to: "0",
        },
      },
    })
    .start()
  basicScroll
    .create({
      elem: rightBoosterRef,
      from: screenWidth <= 414 ? 350 : 0,
      to: screenWidth <= 414 ? 850 : 500,
      direct: false,
      props: {
        "--rr": {
          from: "0",
          to: "150deg",
        },
        "--rry": {
          from: "0px",
          to: "270px",
        },
        "--rrx": {
          from: "0px",
          to: "970px",
        },
        "--ro": {
          from: "1",
          to: "0",
        },
      },
    })
    .start()
  basicScroll
    .create({
      elem: rocketRef,
      from: screenWidth <= 414 ? 350 : 0,
      to: screenWidth <= 414 ? 850 : 500,
      direct: false,
      props: {
        "--my": {
          from: "0px",
          to: "-800px",
        },
      },
    })
    .start()
  basicScroll
    .create({
      elem: opacityRef,
      from: 0,
      to: 50,
      direct: false,
      props: {
        "--op": {
          from: "0",
          to: "1",
        },
        "--goy": {
          from: "-100px",
          to: "0",
        },
      },
    })
    .start()
  return (
    <section className={blogListStyles.section__hero} id={props.id}>
      <div className={blogListStyles.section__hero_content}>
        <div className={blogListStyles.bullet}></div>
        <img className={blogListStyles.arrows} src={arrows} alt="Logo" />
        <div className={layoutStyles.vline}></div>
        <div className={blogListStyles.text_wrapper}>
          <p>
            <b
              data-aos="opacity-up"
              data-aos-delay="0"
              data-aos-duration="1200"
            >
              Ready.
            </b>
            <b
              data-aos="opacity-up"
              data-aos-delay="800"
              data-aos-duration="1200"
            >
              Set...
            </b>
            <b className={blogListStyles.go}>GO!</b>
          </p>
          <h2>
            <b data-aos="custom" data-aos-delay="300" data-aos-duration="1200">
              Hi,{" "}
            </b>
            <b data-aos="custom" data-aos-delay="500" data-aos-duration="1200">
              my{" "}
            </b>
            <b data-aos="custom" data-aos-delay="700" data-aos-duration="1200">
              name is{" "}
            </b>
            <span>
              <b
                data-aos="custom"
                data-aos-delay="900"
                data-aos-duration="1200"
              >
                Ashley{" "}
              </b>
              <b
                data-aos="custom"
                data-aos-delay="1100"
                data-aos-duration="1200"
              >
                Van{" "}
              </b>
              <b
                data-aos="custom"
                data-aos-delay="1300"
                data-aos-duration="1200"
              >
                Laer
              </b>
            </span>
          </h2>
          <div
            className={blogListStyles.text_transition}
            data-aos="sideways"
            data-aos-delay="1500"
            data-aos-duration="1200"
          >
            <h2 className={blogListStyles.second_title}>
              I <b className={blogListStyles.belle}>design</b> and develop{" "}
              <TextLoop>
                {TEXTS.map((item, idx) => (
                  <span key={idx}>{item}</span>
                ))}
              </TextLoop>
            </h2>
          </div>
          <h3
            data-aos="opacity-up"
            data-aos-delay="1600"
            data-aos-duration="1200"
          >
            Let’s see...
          </h3>
        </div>
      </div>
      <div className={blogListStyles.circle} />
      <div
        className={blogListStyles.rocket_wrap}
        data-tyx="15"

      >
        <img
          className={blogListStyles.left_booster}
          ref={leftBoosterRef}
          src={leftBooster}
          alt="leftBooster"
        />
        <img
          className={blogListStyles.rocket}
          ref={rocketRef}
          src={rocket}
          alt="rocket"
        />
        <img
          className={blogListStyles.right_booster}
          ref={rightBoosterRef}
          src={rightBooster}
          alt="rightBooster"
        />
      </div>
    </section>
  )
}
