import React, { useState } from "react"
import aboutStyles from "../styles/components/about.module.scss"
import layoutStyles from "../styles/components/layout.module.scss"
import FeatherIcon from "feather-icons-react"
import pdf from "../assets/resume-ashleyvanlaer2.pdf"
import useSiteMetadata from "../static_queries/useSiteMetadata"

export default function About() {
  const { mySkills } = useSiteMetadata()
  const [isHovering, setIsHovering] = useState({
    idx: null,
    bool: false,
  })

  return (
    <section className={aboutStyles.lab__main}>
      <div className={layoutStyles.vline + " " + layoutStyles.pageLine}></div>
      <div className={aboutStyles.bullet} />
      <div className={aboutStyles.page_titles}>
        <h5>About</h5>
        <h2>
          I have always been interested in creating new things and unique
          experiences. Immersing myself in web development changed many things
          for me. Since then I try to push my work to new levels with every
          project, always prioritising quality.
        </h2>
      </div>
      <div className={aboutStyles.about}>
        <div>
          <h3>Work Experience</h3>
          <ul>
            <li>Full-Stack developer at Mother.life: 2019 - 2020</li>
            <li>Front-end Developer at Tobania: 2020</li>
            <li>Front-end Developer at Buddii & the Island: 2020 - 2021</li>
            <li>Front-end Developer at the flemish government: 2021 - now</li>
          </ul>
        </div>
        <div>
          <h3>Education</h3>
          <ul>
            <li>
              Bachelor New Media and communication technology, Howest: 2016 -
              2019
            </li>
          </ul>
        </div>
        <div className={aboutStyles.skills}>
          <h3>Skills</h3>
          <ul>
            {mySkills.map((skill, idx) => (
              <li
                key={idx}
                onMouseEnter={() =>
                  setIsHovering({
                    idx: idx,
                    bool: true,
                  })
                }
                onMouseLeave={() =>
                  setIsHovering({
                    idx: idx,
                    bool: false,
                  })
                }
              >
                <div
                  className={aboutStyles.overlay_percentage}
                  style={
                    isHovering.idx === idx && isHovering.bool
                      ? {
                          webkitClipPath: `inset(0 ${100 -
                            skill.skill_percentage}% 0 0)`,
                          clipPath: `inset(0 ${100 -
                            skill.skill_percentage}% 0 0)`,
                        }
                      : {}
                  }
                >
                  <p>{skill.skill_name}</p>
                </div>
                <p>{skill.skill_name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <a href={pdf} target="__blank" className={layoutStyles.main_btn}>
        View my resume
        <FeatherIcon
          icon="arrow-right"
          size="22"
          color="#000000"
          strokeWidth="2"
        />
      </a>
    </section>
  )
}
