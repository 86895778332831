import { graphql, useStaticQuery } from "gatsby"

export default function useSiteLogo() {
  const data = useStaticQuery(graphql`
  query getSiteLogo {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            site_logo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  
  `)
  return data.allMarkdownRemark.edges
}