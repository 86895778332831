import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import blogListStyles from "../styles/components/bloglist.module.scss"
import Img from "gatsby-image"
import FeatherIcon from "feather-icons-react"
import Tilt from "react-parallax-tilt"
import Masonry from "react-responsive-masonry"
import layoutStyles from "../styles/components/layout.module.scss"

export default function BlogList(props) {
  const blogData = useBlogData()
  const [screenWidth, setScreenWidth] = useState(0)
  // if (typeof window !== `undefined`) {
  //   window.addEventListener("resize", function() {
  //     setScreenWidth(window.innerWidth)
  //   })
  // }

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", function() {
        setScreenWidth(window.innerWidth)
      })
    }
  }, [screenWidth])

  return (
    <section className={blogListStyles.section__blog} id={props.id}>
      <div className={layoutStyles.vline + " " + layoutStyles.pageLine}></div>
      <div className={blogListStyles.bullet} />
      <Masonry
        columnsCount={screenWidth <= 800 ? 1 : 2}
        gutter={
          screenWidth <= 800
            ? (
              screenWidth <= 600
              ? "80px"
              : "120px"
              )
            : (
              screenWidth <= 1400
            ? screenWidth <= 1150
              ? "60px"
              : "10vw"
            : "170px"
            )
        }
        className={blogListStyles.list}
      >
        <li className={blogListStyles.work__title}>
          <h5>Work</h5>
          <h2>Selected projects</h2>
        </li>
        {blogData
          .filter(
            blog =>
              blog.node.frontmatter.title !== "" &&
              blog.node.frontmatter.hero_image !== null
          )
          .map((blog, idx) => {
            return (
              <div key={idx}>
                <Tilt
                  className={blogListStyles.tilt}
                  tiltReverse={true}
                  glareEnable={false}
                  glareMaxOpacity={0}
                  perspective={1000}
                  transitionSpeed={400}
                  scale={1.05}
                >
                  <Link
                    to={`/blog/${blog.node.fields.slug}`}
                    key={blog.node.id}
                  >
                    <li
                      className={blogListStyles.li}
                      key={blog.node.fields.slug}
                    >
                      <div className={blogListStyles.list__info}>
                        <div className={blogListStyles.list__info_content}>
                          <p className={blogListStyles.list__info_idx}>
                            0{idx}
                          </p>
                          <div className={blogListStyles.list__info_title}>
                            <h2>{blog.node.frontmatter.title}</h2>
                            <span>
                              Explore
                              <FeatherIcon
                                icon="arrow-right"
                                size="22"
                                color="#979797"
                                strokeWidth="2"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={blogListStyles.list__hero}
                        data-aos="fade-right"
                        data-aos-delay="300"
                        data-aos-duration="1200"
                      >
                        <Img
                          fluid={
                            blog.node.frontmatter.hero_image.childImageSharp
                              .fluid
                          }
                          alt={blog.node.frontmatter.title}
                        />
                      </div>
                    </li>
                  </Link>
                </Tilt>
              </div>
            )
          })}
      </Masonry>
    </section>
  )
}
