import React, { useState } from "react"
import labStyles from "../styles/components/lab.module.scss"
import layoutStyles from "../styles/components/layout.module.scss"
import useLabData from "../static_queries/useLabData"

export default function Lab(props) {
  const labData = useLabData()

  return (
    <section className={labStyles.lab__main}>
      <div className={layoutStyles.vline + " " + layoutStyles.pageLine}></div>
      <div className={labStyles.bullet} />
      <div className={labStyles.page_titles}>
        <h5>Lab</h5>
        <h2>
          My other projects like IoT, open source projects, video, experiments.
        </h2>
      </div>
      <div className={labStyles.lab__projects}>
        {labData
          .filter((labCat, ind) => labCat.node.frontmatter.is_lab !== null && ind === labData.findIndex( elem => elem.node.frontmatter.lab_category === labCat.node.frontmatter.lab_category))
          .map((labCat, idx) => {
            return (
              <div>
                <h3>{labCat.node.frontmatter.lab_category}</h3>
                <ul>
                  {labData
                    .filter(labs => labs.node.frontmatter.is_lab !== null)
                    .map((labs, idx) => {
                      if (
                        labs.node.frontmatter.lab_category ===
                        labCat.node.frontmatter.lab_category
                      ) {
                        return (
                          <li>
                            {labCat.node.frontmatter.is_video ? (
                              <>
                                <a
                                  onClick={() =>
                                    props.toggleModal(true, labs.node.frontmatter.video.publicURL, labs.node.frontmatter.thumbnail.childImageSharp.fluid.src)
                                  }
                                >
                                  {labs.node.frontmatter.lab_title}
                                </a>

                              </>
                            ) : (
                              <a
                                href={labs.node.frontmatter.lab_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {labs.node.frontmatter.lab_title}
                              </a>
                            )}
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
            )
          })}
      </div>
    </section>
  )
}
