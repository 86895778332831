import React, { useState } from "react"
import Layout from "../components/Layout"
import BlogList from "../components/BlogList"
import Lab from "../components/Lab"
import Hero from "../components/Hero"
import About from "../components/About"
import Contact from "../components/Contact"
import layoutStyles from "../styles/components/layout.module.scss"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import Header from "../components/Header"
import VideoPlayer from "../components/VideoPlayer"
import FeatherIcon from "feather-icons-react"

export default function IndexPage(props) {
  const { title } = useSiteMetadata()
  const [openVideoModal, setOnVideoModal] = useState(false)
  const [videoPath, setVideoPath] = useState("")
  const [thumbnailPath, setThumbnailPath] = useState("")
  const toggleModal = (modalIsOpened, url, thumbnail) => {
    setVideoPath(url)
    setThumbnailPath(thumbnail)
    console.log(thumbnail)
    if (modalIsOpened) {
      setOnVideoModal(true)
    } else {
      setOnVideoModal(false)
    }
  }
  return (
    <Layout page="home" bgColor="inherit">
      <Header page={props.page} title={title} />
      <section className={layoutStyles.content}>
        <div id="home">
          <Hero />
        </div>
        <div id="work">
          <BlogList />
        </div>
        <div id="lab">
          <Lab toggleModal={toggleModal} />
        </div>

        <div id="about">
          <About />
        </div>

        <div id="contact">
          <Contact />
        </div>
        {openVideoModal && (
          <div className={layoutStyles.videoPopup_container}>
            <div className={layoutStyles.videoPopup}>
            <a onClick={() => toggleModal(false)} className={layoutStyles.back_btn}>
                <FeatherIcon
                  icon="x"
                  size="34"
                  color="#000"
                  strokeWidth="2"
                />
              </a>
            <VideoPlayer
              toggleModal={toggleModal}
              media={{
                video: videoPath,
                thumbnail: thumbnailPath,
              }}
            />
            </div>

          </div>
        )}
      </section>
    </Layout>
  )
}
