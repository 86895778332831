import { graphql, useStaticQuery } from "gatsby"

export default function useLabData() {
  const data = useStaticQuery(graphql`
    query getLabData {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            id
            frontmatter {
              is_lab
              is_video
              lab_title
              lab_category
              lab_url
              video {
                publicURL
              }
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.edges
}
